/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { gql } from 'graphql-request';
import { useQuery } from 'react-query';
import React from 'react';
import orderBy from 'lodash/orderBy';
import Link from 'next/link';
import sumBy from 'lodash/sumBy';
import styled from 'styled-components';
import { Thead, Tbody, Tr, Td } from 'react-super-responsive-table';
import { parseISO } from 'date-fns';
import { uniqBy } from 'lodash';
import { tokenFragment, useTransformToken } from '~/graphql';
import { formatAmount, getPerfColor, xtz } from '~/utils';
import Table, { SortableTh, tableHeaderHeight } from './Table';
import { DIVIDER } from '~/utils/const';
import StatusText from './StatusText';
import { getUserHoldings } from '~/hooks/useCurrentUserHoldings';
import ShowMoreButton from './ShowMoreButton';
import { getOrderedSwaps } from '~/pageComponents/ObjktPage';
import { Objkt, Swap, Trade } from '~/types';
import { useDataContext } from '~/contexts/Data';
import UserTableItem from './UserTableItem';
import { calendar } from '~/hooks/useTimeAgo';
import useBreakpoint from '~/hooks/useBreakpoint';
import { headerHeight } from './Layout';
import ExternalLink from './ExternalLink';
import TextTruncated from './TextTruncated';
import TextLight from './TextLight';
import TableStats from './TableStats';
import useOrderTable from '~/hooks/useOrderTable';
import useShowMore from '~/hooks/useShowMore';
import LoadIcon from './LoadIcon';
import useGraphqlClient from '~/hooks/useGraphqlClient';

const StyledTable = styled(Table)`
  th {
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.textLight};
  }
`;

const CollectorFeedQuery = gql`
  query CollectorFeed(
    $address: String,
    $limit: Int,
  ) {
    token_holder(
      limit: $limit,
      where: {
        holder_id: {
          _eq: $address
        },
        token: {
          creator: {
            address: {
              _neq: $address
            }
          }
        },
        quantity: {
          _gt: 0
        }
      },
      order_by: {
        token_id: desc
      }
    ) {
      token {
        ${tokenFragment}
      }
    }
  }
`;

export const getAcquisitionTrades = ({
  trades = [],
  address,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trades: Trade[];
  address: string;
}): Trade[] => orderBy(trades.filter(Boolean), ['timestamp'], ['desc']).filter(({
  buyer,
}) => buyer.address === address);

export const getObjktLatestSales = ({
  trades,
}: Objkt): Trade[] => orderBy(trades.filter(Boolean), ['timestamp'], ['desc']);

const useUserHoldings = (address: string) => {
  const transformToken = useTransformToken();
  const gqlClient = useGraphqlClient();
  return useQuery<Objkt[]>(
    ['collector.feed', address],
    async () => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
      const { token_holder } = await gqlClient(
        CollectorFeedQuery,
        { address, limit: 9999 },
      );
      return token_holder.map(({ token }) => transformToken(token));
    },
    { enabled: !!address },
  );
};

export const getTotalValue = (token, address) => {
  const sellers = getOrderedSwaps(token);
  let holdings = +getUserHoldings(token, address).total;
  let totalValue = 0;
  for (const seller of sellers) {
    const { amount_left, price } = seller as unknown as Swap;
    for (let i = 0; i < amount_left; i += 1) {
      if (holdings > 0) {
        totalValue += price;
        holdings -= 1;
      }
    }
  }
  return totalValue / DIVIDER;
};

type ValuationRow = {
  id: string;
  timestamp: string;
  objkt: Objkt;
  cost: number;
  costHash: string;
  lastUniqueSale: number;
  lastUniqueSaleHash: string;
  floor: number;
  floorHash: string;
  floorProfit: number;
  lastSale: number;
  lastSaleHash: string;
  lastSaleProfit: number;
};

type ValuationData = {
  isLoading: boolean;
  stats: {
    assetsCount: number;
    totalCost: number;
    totalFloor: number;
    totalFloorProfit: number;
    totalLastSale: number;
    totalLastSaleProfit: number;
  };
  rows: ValuationRow[];
};

export const getFloorPrice = (objkt: Objkt): {
  floor: number;
  floorHash: string;
} => {
  const sellers = getOrderedSwaps(objkt) || [];
  return {
    floor: sellers?.[0]?.price / DIVIDER || 0,
    floorHash: sellers?.[0]?.ophash,
  };
};

const getUniqueCreators = (objkts: Objkt[] = []): string[] => uniqBy(
  objkts
    .filter(({ supply }) => supply === 1)
    .map(({ creator }) => creator.address),
  (o) => o,
);

const UniqueTradesQuery = gql`
query UniqueTrades($addresses: [String!]) {
  trade(
    where: {
      token: {
        supply: {
          _eq: "1"
        }, 
        creator: {
          address: {
            _in: $addresses
          }
        }
      }
    },
    order_by: {
      timestamp: desc
    }, 
  ) {
    ophash
    swap {
      price
    }
    token {
      creator_id
    }
  }
}
`;

type LastUniquesList = Record<string, {
  ophash: string;
  price: number;
}>;

const useLastUniqueSales = (addresses: string[] = []) => {
  const gqlClient = useGraphqlClient();
  return useQuery<LastUniquesList>(
    ['unique.sales', ...addresses.sort()],
    async () => {
      const {
        trade = [],
      } = await gqlClient(
        UniqueTradesQuery,
        { addresses },
      );
      const data = trade
        .reverse()
        .reduce((acc, { ophash, swap, token }) => ({
          ...acc,
          [token.creator_id]: {
            ophash,
            price: swap.price / DIVIDER,
          },
        }), {} as LastUniquesList);
      console.log('Got unique trades.', { trade, data });
      return data;
    },
    { enabled: addresses.length > 0 },
  );
};

const useValuationData = (address: string): ValuationData => {
  const { data: objkts = [], status } = useUserHoldings(address);
  const uniqueCreators = getUniqueCreators(objkts);
  const { data: uniqueSales = {} } = useLastUniqueSales(uniqueCreators);
  const isLoading = status === 'loading';
  const rows = objkts.reduce((acc, objkt) => {
    const trades = getAcquisitionTrades({ trades: objkt.trades, address }) || [];
    const sales = getObjktLatestSales(objkt) || [];
    const holdings = +getUserHoldings(objkt, address).total;
    const { floor, floorHash } = getFloorPrice(objkt);
    const _rows = Array
      .from({ length: holdings })
      .map((_, index) => index)
      .map((index): ValuationRow => {
        const trade = trades[index] || {} as Trade;
        const { timestamp } = trade;
        const id = [objkt.id, trade.id, index].filter(Boolean).join('.');
        const cost = trades[index]?.swap.price / DIVIDER || 0;
        const costHash = trades[index]?.ophash;
        const royaltiesMultiplier = (1 - objkt.royalties / 1000);
        const floorProfit = floor ? floor * royaltiesMultiplier - cost : 0;
        const lastUniqueSale = objkt.supply === 1 ? uniqueSales[objkt.creator.address]?.price : 0;
        const lastUniqueSaleHash = objkt.supply === 1 ? uniqueSales[objkt.creator.address]?.ophash : '';
        const lastSale = lastUniqueSale || sales[0]?.swap?.price / DIVIDER || 0;
        const lastSaleHash = lastUniqueSaleHash || sales[0]?.ophash;
        const lastSaleProfit = lastSale ? lastSale * royaltiesMultiplier - cost : 0;
        return {
          id,
          timestamp,
          objkt,
          cost,
          costHash,
          lastUniqueSale,
          lastUniqueSaleHash,
          floor,
          floorHash,
          floorProfit,
          lastSale,
          lastSaleHash,
          lastSaleProfit,
        };
      });
    return [...acc, ..._rows];
  }, [] as ValuationRow[]);
  const stats = {
    assetsCount: rows.length,
    totalCost: sumBy(rows, 'cost'),
    totalFloor: sumBy(rows, 'floor'),
    totalFloorProfit: sumBy(rows, 'floorProfit'),
    totalLastSale: sumBy(rows, 'lastSale'),
    totalLastSaleProfit: sumBy(rows, 'lastSaleProfit'),
  };
  return {
    isLoading,
    stats,
    rows,
  };
};

const tops = [headerHeight, headerHeight + tableHeaderHeight];

const ValuationFeed: React.FC<{ address: string }> = ({
  address,
}) => {
  const {
    stats: {
      assetsCount,
      totalCost,
      totalFloor,
      totalFloorProfit,
      totalLastSale,
      totalLastSaleProfit,
    },
    rows = [],
    isLoading,
  } = useValuationData(address);
  const {
    handleClickHeader,
    orderKey,
    orderAscending,
    orderedRows,
  } = useOrderTable<ValuationRow>(rows);
  const {
    limitedRows,
    canShowMore,
    showMore,
  } = useShowMore<ValuationRow>(orderedRows);
  const { price, currency } = useDataContext();
  const isEth = currency === 'eth';
  const breakpoint = useBreakpoint();
  return isLoading ? <LoadIcon $animating style={ { margin: 15, marginLeft: 10 } } />
    : isLoading ? <StatusText>Something went wrong</StatusText>
      : rows.length === 0 ? <StatusText>The porftolio is empty</StatusText>
        : (
          <>
            <TableStats data={
              [
                {
                  label: 'Total assets',
                  value: assetsCount,
                },
                {
                  label: 'Total cost',
                  value: totalCost,
                  currency: xtz,
                  sub: price && currency && `(${formatAmount(totalCost * price, isEth ? 3 : 0)} ${currency})`,
                },
                {
                  label: 'Total floor',
                  value: totalFloor,
                  currency: xtz,
                  sub: price && currency && `(${formatAmount(totalFloor * price, isEth ? 3 : 0)} ${currency})`,
                },
                {
                  label: 'Gain / floor',
                  value: totalFloorProfit,
                  color: getPerfColor(totalFloorProfit),
                  currency: xtz,
                  sub: price && currency && `(${formatAmount((totalFloorProfit) * price, isEth ? 3 : 0)} ${currency})`,
                },
                {
                  label: 'Total last sale',
                  value: totalLastSale,
                  currency: xtz,
                  sub: price && currency && `(${formatAmount(totalLastSale * price, isEth ? 3 : 0)} ${currency})`,
                },
                {
                  label: 'Gain / last sale',
                  value: totalLastSaleProfit,
                  color: getPerfColor(totalLastSaleProfit),
                  currency: xtz,
                  sub: price && currency && `(${formatAmount((totalLastSaleProfit) * price, isEth ? 3 : 0)} ${currency})`,
                },
              ]
            }
            />
            <TextLight style={ { marginTop: 15, marginBottom: 5 } }>
              * For objkts with 1 single edition, the last sale prices are the prices of other unique objkts from the same creator.
            </TextLight>
            <StyledTable key={ `table.${breakpoint}` }>
              <Thead>
                <Tr>
                  <th colSpan={ 6 } style={ { top: tops[0] } }>
                    <div />
                  </th>
                  <th colSpan={ 2 } style={ { top: tops[0], textAlign: 'center' } }>
                    FLOOR
                  </th>
                  <th colSpan={ 2 } style={ { top: tops[0], textAlign: 'center' } }>
                    LAST SALE *
                  </th>
                </Tr>
              </Thead>
              <Thead>
                <Tr>
                  <SortableTh
                    style={ { top: tops[1] } }
                    onClick={ () => handleClickHeader('objkt.title') }
                    $active={ orderKey === 'objkt.title' }
                    $ascending={ orderAscending }
                  >
                    Objkt
                  </SortableTh>
                  <SortableTh
                    style={ { top: tops[1] } }
                    onClick={ () => handleClickHeader('objkt.creator.name') }
                    $active={ orderKey === 'objkt.creator.name' }
                    $ascending={ orderAscending }
                  >
                    Artist
                  </SortableTh>
                  <SortableTh
                    style={ { top: tops[1] } }
                    onClick={ () => handleClickHeader('timestamp') }
                    $active={ orderKey === 'timestamp' }
                    $ascending={ orderAscending }
                  >
                    Date
                  </SortableTh>
                  <SortableTh
                    style={ { top: tops[1], textAlign: 'right' } }
                    onClick={ () => handleClickHeader('objkt.supply') }
                    $active={ orderKey === 'objkt.supply' }
                    $ascending={ orderAscending }
                  >
                    Editions
                  </SortableTh>
                  <SortableTh
                    style={ { top: tops[1], textAlign: 'right' } }
                    onClick={ () => handleClickHeader('objkt.royalties') }
                    $active={ orderKey === 'objkt.royalties' }
                    $ascending={ orderAscending }
                  >
                    Royalties
                  </SortableTh>
                  <SortableTh
                    style={ { top: tops[1], textAlign: 'right' } }
                    onClick={ () => handleClickHeader('cost') }
                    $active={ orderKey === 'cost' }
                    $ascending={ orderAscending }
                  >
                    Cost
                  </SortableTh>
                  <SortableTh
                    style={ { top: tops[1], textAlign: 'right' } }
                    onClick={ () => handleClickHeader('floor') }
                    $active={ orderKey === 'floor' }
                    $ascending={ orderAscending }
                  >
                    { breakpoint === 'mobile' ? 'Floor price' : 'Price' }
                  </SortableTh>
                  <SortableTh
                    style={ { top: tops[1], textAlign: 'right' } }
                    onClick={ () => handleClickHeader('floorProfit') }
                    $active={ orderKey === 'floorProfit' }
                    $ascending={ orderAscending }
                  >
                    { breakpoint === 'mobile' ? 'Gain / floor' : 'Gain' }
                  </SortableTh>
                  <SortableTh
                    style={ { top: tops[1], textAlign: 'right' } }
                    onClick={ () => handleClickHeader('lastSale') }
                    $active={ orderKey === 'lastSale' }
                    $ascending={ orderAscending }
                  >
                    { breakpoint === 'mobile' ? 'Last sale price' : 'Price' }
                  </SortableTh>
                  <SortableTh
                    style={ { top: tops[1], textAlign: 'right' } }
                    onClick={ () => handleClickHeader('lastSaleProfit') }
                    $active={ orderKey === 'lastSaleProfit' }
                    $ascending={ orderAscending }
                  >
                    { breakpoint === 'mobile' ? 'Gain / last' : 'Gain' }
                  </SortableTh>
                </Tr>
              </Thead>
              <Tbody>
                {
                  limitedRows.map(({
                    id,
                    objkt,
                    cost,
                    costHash,
                    floor,
                    floorHash,
                    floorProfit,
                    lastSale,
                    lastSaleHash,
                    lastSaleProfit,
                    timestamp,
                  }) => (
                    <Tr key={ `valuation.${address}.${id}` }>
                      <Td>
                        <TextTruncated>
                          <Link href={ `/o/${objkt.id}` }>
                            {`${objkt.title}`}
                          </Link>
                        </TextTruncated>
                      </Td>
                      <Td>
                        <TextTruncated>
                          <UserTableItem user={ objkt.creator } />
                        </TextTruncated>
                      </Td>
                      <Td style={ { textAlign: 'right' } }>
                        { timestamp ? calendar(parseISO(timestamp)) : '-' }
                      </Td>
                      <Td style={ { textAlign: 'right' } }>
                        { formatAmount(objkt.supply) }
                      </Td>
                      <Td style={ { textAlign: 'right' } }>
                        { `${formatAmount(objkt.royalties / 10)}%` }
                      </Td>
                      <Td style={ { textAlign: 'right' } }>
                        <ExternalLink href={ costHash && `https://tzkt.io/${costHash}` }>
                          { cost ? formatAmount(cost, 1) : '-' }
                        </ExternalLink>
                      </Td>
                      <Td style={ { textAlign: 'right' } }>
                        <ExternalLink href={ floorHash && `https://tzkt.io/${floorHash}` }>
                          { floor ? formatAmount(floor, 1) : '-' }
                        </ExternalLink>
                      </Td>
                      <Td style={ { textAlign: 'right', color: getPerfColor(floorProfit) } }>
                        { floorProfit ? `${formatAmount(floorProfit, 1)}` : '-' }
                      </Td>
                      <Td style={ { textAlign: 'right' } }>
                        <ExternalLink href={ lastSaleHash && `https://tzkt.io/${lastSaleHash}` }>
                          { lastSale ? formatAmount(lastSale, 1) : '-' }
                        </ExternalLink>
                      </Td>
                      <Td style={ { textAlign: 'right', color: getPerfColor(lastSaleProfit) } }>
                        { lastSaleProfit ? `${formatAmount(lastSaleProfit, 1)}` : '-' }
                      </Td>
                    </Tr>
                  ))
                }
              </Tbody>
            </StyledTable>
            {
              canShowMore ? (
                <ShowMoreButton onClick={ showMore }>Show more</ShowMoreButton>
              ) : null
            }
          </>
        );
};

export default ValuationFeed;

import React from 'react';
import styled from 'styled-components';
import { media } from '~/styles/media';
import { formatAmount } from '~/utils';

const StatsContainer = styled.div<{ $columns?: number }>`
  display: grid;
  grid-template-columns: repeat(${({ $columns }) => $columns}, 1fr);
  grid-gap: 15px;
  margin-top: 15px;
  ${media.mobile`
    display: block;
  `}
`;

const StatItem = styled.div<{ $color?: string }>`
  background-color: ${({ theme }) => theme.colors.opacity};
  padding: 10px 15px;
  text-align: center;
  div {
    font-weight: bold;
    color: ${({ $color }) => $color};
  }
  display: flex;
  flex-direction: column;
  ${media.mobile`
    margin-bottom: 2px;
    flex-direction: row;
    div,
    label {
      margin-right: 15px;
    }
  `}
  label {
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.textLight};
    font-weight: bold;
  }
`;

type TableStat = {
  title?: string;
  label?: string;
  value?: number;
  currency?: string;
  color?: string;
  sub?: string;
};

const TableStats: React.FC<{
  data: TableStat[];
  className?: string;
  style?: React.CSSProperties;
}> = ({
  data,
  style,
  className,
}) => (
  <StatsContainer className={ className } style={ style } $columns={ data.length }>
    {
      data.map(({ title, label, value, currency, color, sub }) => (
        <StatItem key={ `stat.${label}` } $color={ color }>
          <label>{ label }</label>
          <div>{title || [formatAmount(value, 1), currency].filter(Boolean).join(' ')}</div>
          {
            sub ? (
              <div style={ { fontWeight: 'normal' } }>{ sub }</div>
            ) : null
          }
        </StatItem>
      ))
    }
  </StatsContainer>
);

export default TableStats;

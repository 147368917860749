import { orderBy } from 'lodash';
import React from 'react';

function useOrderTable<T>(rows: T[]) {
  const handleClickHeader = (k) => {
    setOrderBy(k);
    setOrderAscending((d) => !d);
  };
  const [orderKey, setOrderBy] = React.useState<string>('lastSaleProfit');
  const [orderAscending, setOrderAscending] = React.useState<boolean>(false);
  const orderedRows = orderBy(rows,
    [orderKey],
    [orderAscending ? 'asc' : 'desc']);
  return {
    handleClickHeader,
    orderKey,
    orderAscending,
    orderedRows,
  };
}

export default useOrderTable;

import { gql } from 'graphql-request';
import { orderBy } from 'lodash';
import { useQuery } from 'react-query';
import { userFragment, useTransformToken } from '~/graphql';
import { Activity } from '~/types';
import useGraphqlClient from './useGraphqlClient';

const tradeFragment = `
  id
  timestamp
  ophash
  buyer {
    ${userFragment}
  }
  seller {
    ${userFragment}
  }
  token {
    id
    title
    display_uri
    royalties
    timestamp
    creator {
      ${userFragment}
    }
  }
  swap {
    price
  }
`;

const UserTradeQuery = gql`
  query UserTradeQuery($address: String) {
    buyer: trade(
      where: {
        buyer: {
          address: {
            _eq: $address
          }
        }
      }
    ) {
      ${tradeFragment}
    }
    seller: trade(
      where: {
        seller: {
          address: {
            _eq: $address
          }
        }
      }
    ) {
      ${tradeFragment}
    }
  }
`;

const useUserTrades = (address: string) => {
  const transformToken = useTransformToken();
  const gqlClient = useGraphqlClient();
  return useQuery<Activity[]>(
    ['user.trades', address],
    async () => {
      const { buyer = [], seller = [] } = await gqlClient(
        UserTradeQuery,
        { address },
      );
      return orderBy(
        [...buyer, ...seller].map((o) => ({
          ...o,
          quantity: 1,
          type: o.buyer.address === address ? 'buy' : 'sell',
          price: o.swap?.price,
          token: transformToken(o.token),
        })),
        ['timestamp'],
        ['desc'],
      );
    },
    {
      enabled: !!address,
    },
  );
};

export default useUserTrades;


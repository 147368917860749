/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'graphql-request';
import { useInfiniteQuery, useQuery } from 'react-query';
import React from 'react';
import orderBy from 'lodash/orderBy';
import { tokenFragmentLite, useTransformToken } from '~/graphql';
import TokenList from './TokenList';
import ObjktFilterRow from './ObjktFilterRow';
import {
  getSearchKey,
  getSearchParams,
  getItemsFromQuery,
  getNextPageParam,
} from '~/hooks/useSearchObjkts';
import { orderByFix } from '~/utils';
import FetchMoreButton from './FetchMoreButton';
import { useAudioPlayer } from '~/contexts/AudioPlayer';
import { isAudioObjkt } from '~/utils/mime';
import { useDataContext } from '~/contexts/Data';
import { useFilterQueryParams } from '~/hooks/useFilter';
import { CollectorFeedSearchQuery } from '~/hooks/useCollectionCount';
import { Objkt } from '~/types';
import useGraphqlClient from '~/hooks/useGraphqlClient';

const CollectorFeedQuery = gql`
  query CollectorFeed(
    $where: token_holder_bool_exp,
    $orderBy: [token_holder_order_by!] = { id: desc },
    $limit: Int,
    $offset: Int,
  ) {
    token_holder(
      where: $where,
      order_by: $orderBy,
      limit: $limit,
      offset: $offset,
    ) {
      token {
        ${tokenFragmentLite}
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Trade = any;

const getTradesFrom = ({
  trades = [],
  address,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trades: Trade[];
  address: string;
}): Trade => orderBy(trades, ['timestamp'], ['desc']).filter(({
  buyer,
}) => buyer.address === address);

const getCollectorSearchParams = ({
  address,
  values,
  limit = 20,
  offset,
  blockedWallets = [],
}: {
  address: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any;
  limit?: number;
  offset?: number;
  blockedWallets: string[];
}) => {
  const tokenSearchParams = getSearchParams(values, blockedWallets);
  return {
    limit,
    offset,
    where: {
      holder_id: {
        _eq: address,
      },
      token: {
        ...(tokenSearchParams?.where || {}),
        creator: {
          address: {
            _neq: address,
          },
        },
        artifact_uri: {
          _neq: '',
        },
      },
      quantity: {
        _gt: 0,
      },
    },
    orderBy: tokenSearchParams?.orderBy || {
      token_id: 'desc',
    },
  };
};

const useCollectorSearch = (address, { limit }) => {
  const { blockedWallets } = useDataContext();
  const { setPlaylist } = useAudioPlayer();
  const transformToken = useTransformToken();
  const values = useFilterQueryParams();
  const gqlClient = useGraphqlClient();
  const query = useInfiniteQuery(
    getSearchKey({ ...values, owner: address }),
    async ({ pageParam: offset }) => {
      const { token_holder } = await gqlClient(
        CollectorFeedQuery,
        getCollectorSearchParams({ values, address, limit, offset, blockedWallets }),
      );
      return token_holder.map(({ token }) => transformToken(token));
    },
    {
      enabled: !!address,
      getNextPageParam,
    },
  );
  const tokens = getItemsFromQuery<Objkt>(query);
  React.useEffect(() => {
    setPlaylist(tokens.filter(isAudioObjkt));
  }, [setPlaylist, JSON.stringify(tokens)]);
  return { ...query, tokens };
};

export const useCollectorSearchCount = (address) => {
  const { blockedWallets } = useDataContext();
  const values = useFilterQueryParams();
  const gqlClient = useGraphqlClient();
  const query = useQuery(
    ['count', ...getSearchKey({ ...values, owner: address })],
    async () => {
      const { token_holder_aggregate } = await gqlClient(
        CollectorFeedSearchQuery,
        { where: getCollectorSearchParams({ values, address, blockedWallets }).where },
      );
      return token_holder_aggregate?.aggregate?.count ?? 0;
    },
    {
      enabled: !!address,
    },
  );
  return {
    ...query,
    count: query.data,
    countLoading: query.status === 'loading',
  };
};

const CollectorFeed: React.FC<{
  address: string;
}> = ({
  address,
}) => {
  const {
    status,
    tokens = [],
    fetchNextPage,
    isFetchingNextPage,
  } = useCollectorSearch(address, { limit: 20 });
  const { count, countLoading } = useCollectorSearchCount(address);
  const {
    orderKey,
    orderDir,
  } = useFilterQueryParams();
  const filteredTokens = React.useMemo(() => orderByFix(
    tokens.map(({ trades = [], ...token }) => {
      const tradesFrom = getTradesFrom({ trades, address }) || [];
      const acquiredAt = tradesFrom[0]?.timestamp;
      return {
        ...token,
        acquiredAt,
      };
    }), [orderKey], [orderDir],
  ), [JSON.stringify(tokens), address, orderDir, orderKey]);
  const canFetchMore = tokens.length > 0 && tokens.length < count;
  return (
    <>
      <ObjktFilterRow count={ count } countLoading={ countLoading } address={ address } />
      <TokenList tokens={ filteredTokens } isLoading={ status === 'loading' } walletOf={ address } />
      {
        canFetchMore ? (
          <FetchMoreButton { ...{ fetchNextPage, isFetchingNextPage } } />
        ) : null
      }
    </>
  );
};

export default CollectorFeed;

import axios from 'axios';
import { uniqBy } from 'lodash';
import { useQuery } from 'react-query';
import keyBy from 'lodash/keyBy';
import { useDataContext } from '~/contexts/Data';
import { Activity } from '~/types';
import { ADDRESSES } from '~/utils/addresses';
import { getObjkts } from '~/hooks/useObjkts';
import { getUsersData } from '~/hooks/useUsers';

import useGraphqlClient from '~/hooks/useGraphqlClient';

type GivewayType = 'sender' | 'receiver';
const defaultDirection = 'receiver';

// eslint-disable-next-line max-len
export const getUserTransfers = (
  address: string,
  direction: GivewayType = defaultDirection,
) => axios.get(`https://api.tzkt.io/v1/accounts/${ADDRESSES.objkts}/operations`, {
  params: {
    [`parameter.[*].txs.[*].${direction === 'sender' ? 'from_' : 'to_'}`]: address,
    'parameter.[*].from_.ni': [ADDRESSES.v1, ADDRESSES.v2].join(','),
    'sender.ni': ['KT1Dno3sQZwR5wUCWxzaohwuJwG3gX1VWj1Z', 'KT1FvqJwEDWb1Gwc55Jd1jjTHRVWbYKUUpyq'].join(','),
    status: 'applied',
    type: 'transaction',
    limit: 1000,
  },
})
  .then(({ data = [] }) => data.filter(({ type }) => type === 'transaction')
    .map(({
      timestamp,
      sender,
      parameter,
      hash,
    }) => ({
      timestamp,
      from: direction === 'sender' ? address : sender.address,
      to: direction === 'sender' ? sender.address : address,
      objktId: +parameter.value?.[0]?.txs?.[0].token_id,
      quantity: +parameter.value?.[0]?.txs?.[0].amount,
      ophash: hash,
    })));

const useUserTransfers = (address: string, direction: GivewayType) => {
  const { blockedWallets } = useDataContext();
  // const transformToken = useTransformToken();
  const client = useGraphqlClient();
  return useQuery<Activity[]>(
    ['user', direction, address],
    async () => {
      const giveways = await getUserTransfers(address, direction);
      const tokens = await getObjkts({
        ids: giveways.map(({ objktId }) => objktId),
        blockedWallets,
        client,
      });
      const usersIds = uniqBy(giveways.reduce((acc, { to, from }) => ([...acc, to, from]), []), (x) => x) as string[];
      const users = await getUsersData(usersIds, client);
      const indexedUsers = keyBy(users, 'address');
      return giveways.map((g) => ({
        ...g,
        type: 'transfer',
        token: tokens?.find(({ id }) => id === g.objktId),
        price: 0,
        seller: (direction === 'sender' ? indexedUsers[g.to] : indexedUsers[g.from]) || {
          address: direction === 'sender' ? g.to : g.from,
        },
        buyer: (direction === 'sender' ? indexedUsers[g.from] : indexedUsers[g.to]) || {
          address: direction === 'sender' ? g.from : g.to,
        },
      }));
    },
    {
      enabled: !!address,
    },
  );
};

export default useUserTransfers;

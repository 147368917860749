import orderBy from 'lodash/orderBy';
import { Activity } from '~/types';
import useUserTrades from '~/hooks/useUserTrades';
import { useUserActivitySwaps } from '~/hooks/useUserSwaps';
import useUserTransfers from '~/hooks/useUserTransfers';

const useUserActivity = (address?: string): [Activity[], boolean] => {
  const { data: trades = [], isLoading: isLoadingTrades } = useUserTrades(address);
  const { data: swaps = [], isLoading: isLoadingSwaps } = useUserActivitySwaps(address);
  const { data: sent = [], isLoading: isLoadingSent } = useUserTransfers(address, 'sender');
  const { data: received = [], isLoading: isLoadingReceived } = useUserTransfers(address, 'receiver');
  const items = orderBy([
    ...trades,
    ...(swaps as unknown as Activity[]),
    ...sent,
    ...received,
  ], ['timestamp'], ['desc']);
  const isLoading = isLoadingTrades || isLoadingSwaps || isLoadingSent || isLoadingReceived;
  return [items, isLoading];
};

export default useUserActivity;

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { intervalToDuration, parseISO } from 'date-fns';
import { orderBy, sumBy } from 'lodash';
import { Thead, Tbody, Tr, Td } from 'react-super-responsive-table';
import Link from 'next/link';
import { useQuery } from 'react-query';
import { gql } from 'graphql-request';
import useOrderTable from '~/hooks/useOrderTable';
import { calendar } from '~/hooks/useTimeAgo';
import useUserTrades from '~/hooks/useUserTrades';
import { Activity, Objkt, User } from '~/types';
import Table, { SortableTh } from './Table';
import TableStats from './TableStats';
import TextTruncated from './TextTruncated';
import UserTableItem from './UserTableItem';
import LoadIcon from '~/components/LoadIcon';
import ExternalLink from './ExternalLink';
import { formatAmount, getPerfColor, xtz } from '~/utils';
import { DIVIDER } from '~/utils/const';
import StatusText from './StatusText';
import { userFragment } from '~/graphql';
import Emoji from './Emoji';
import ShowMoreButton from './ShowMoreButton';
import useShowMore from '~/hooks/useShowMore';
import TextLight from './TextLight';
import useBreakpoint from '~/hooks/useBreakpoint';
import { useDataContext } from '~/contexts/Data';
import useGraphqlClient from '~/hooks/useGraphqlClient';

type ProfitRow = {
  id: string | number;
  type: 'sale' | 'salePrimary' | 'saleSecondary' | 'royalties';
  timestamp: string;
  duration: number;
  durationLabel: string;
  objkt: Partial<Objkt>;
  paid: number;
  paidHash: string;
  received: number;
  receivedHash: string;
  gain: number;
  isSecondary: boolean;
  isCreation: boolean;
};

const getDurationLabel = (start: string, end: string) => {
  const {
    years,
    months,
    days,
    hours,
    minutes,
    seconds,
  } = (start && end && intervalToDuration({
    start: parseISO(start).getTime(),
    end: parseISO(end).getTime(),
  })) || {};
  if (years) return `${years} year${years > 1 ? 's' : ''}`;
  if (months) return `${months} month${months > 1 ? 's' : ''}`;
  if (days) return `${days} day${days > 1 ? 's' : ''}`;
  if (hours) return `${hours} hour${hours > 1 ? 's' : ''}`;
  if (minutes) return `${minutes} minute${minutes > 1 ? 's' : ''}`;
  if (seconds) return `${seconds} second${seconds > 1 ? 's' : ''}`;
  return '-';
};

const transformTrade = ({
  id,
  timestamp: sellTimestamp,
  token: objkt,
  ophash: receivedHash,
  swap,
  seller,
}: Activity, trades: Activity[], address: string): ProfitRow => {
  const objktBuysBefore = trades.filter(({ token, type, timestamp: buyTimestamp }) => token.id === objkt.id
          && type === 'buy'
        && buyTimestamp < sellTimestamp);
  const buyTx = objktBuysBefore[0];
  const buyTimestamp = buyTx?.timestamp || objkt.timestamp;
  const duration = parseISO(sellTimestamp).getTime() - parseISO(buyTimestamp).getTime() || 0;
  const durationLabel = getDurationLabel(buyTimestamp, sellTimestamp);
  const paid = buyTx?.swap?.price / DIVIDER || 0;
  const paidHash = buyTx?.ophash;
  const sale = swap.price / DIVIDER;
  const isSecondary = objkt.creator?.address !== seller?.address;
  const isCreation = objkt.creator?.address === address;
  const royalties = (sale * objkt.royalties) / 1000;
  const fees = sale * 0.025;
  const received = sale;
  const gain = received - fees - (isSecondary ? 0 : royalties) - paid;
  return {
    id,
    type: isSecondary ? 'saleSecondary' : 'salePrimary',
    timestamp: sellTimestamp,
    duration,
    durationLabel,
    objkt,
    paid,
    paidHash,
    received,
    receivedHash,
    gain,
    isSecondary,
    isCreation,
  };
};

const useClosedTrades = (address: string) => {
  const query = useUserTrades(address);
  const { data: trades = [] } = query;
  const closedTrades = trades.filter(({ type }) => type === 'sell').map((t) => transformTrade(t, trades, address));
  return {
    ...query,
    data: closedTrades,
  };
};

type RoyaltyTrade = {
  id: string;
  timestamp: string;
  ophash: string;
  token: Objkt;
  seller: User;
  swap: {
    price: number;
  };
};

const transformRoyaltiesTrade = ({
  id,
  timestamp,
  ophash,
  token: objkt,
  swap,
  seller,
}: RoyaltyTrade, address: string): ProfitRow => ({
  id: `royalties.${id}`,
  type: 'royalties',
  timestamp,
  duration: 0,
  durationLabel: null,
  objkt,
  paid: null,
  paidHash: null,
  received: ((swap.price / DIVIDER) * objkt.royalties) / 1000,
  receivedHash: ophash,
  gain: ((swap.price / DIVIDER) * objkt.royalties) / 1000,
  isSecondary: objkt.creator?.address !== seller?.address,
  isCreation: objkt.creator?.address === address,
});

const useRoyalties = (address: string) => {
  const query = gql`
    query CreatorSales($address: String!) {
        trade(
            where: {
                token: {
                    creator_id: {
                        _eq: $address
                    }
                },
                seller: {
                    address: {
                        _neq: $address
                    }
                }
            },
            order_by: {
                swap: {
                    price: desc
                }
            }
        ) {
            id
            timestamp
            ophash
            token {
                id
                title
                display_uri
                royalties
                timestamp
                creator {
                    ${userFragment}
                }
            }
            seller {
                address
            }
            swap {
                price
            }
        }
    }
  `;
  const variables = {
    address,
  };
  const gqlClient = useGraphqlClient();
  return useQuery(
    ['creator.trades', address],
    async () => {
      const { trade: trades = [] } = (await gqlClient(
        query,
        variables,
      )) as {
        trade: RoyaltyTrade[]
      };
      return trades.map((t) => transformRoyaltiesTrade(t, address)).filter(Boolean);
    },
    { enabled: !!address },
  );
};

const useProfitRows = (address: string) => {
  const { data: trades = [], isLoading: isLoadingTrades } = useClosedTrades(address);
  const { data: royalties = [], isLoading: isLoadingRoyalties } = useRoyalties(address);
  const rows = orderBy(
    [...trades, ...royalties],
    ['timestamp'],
    ['desc'],
  );
  return {
    rows,
    isLoading: isLoadingTrades || isLoadingRoyalties,
  };
};

const typeEmojis = {
  sale: '🤝',
  salePrimary: '🤝',
  saleSecondary: '🤝',
  royalties: '💸',
};

const typeLabels = {
  sale: 'Sale',
  salePrimary: 'Primary sale',
  saleSecondary: 'Secondary sale',
  royalties: 'Royalties',
};

const ProfitFeed = ({ address }) => {
  const { rows, isLoading } = useProfitRows(address);
  const totalPaid = sumBy(rows, 'paid') || 0;
  const totalReceived = sumBy(rows, 'received') || 0;
  const totalGain = sumBy(rows, 'gain') || 0;
  const totalPerf = ((totalReceived - totalPaid) / totalPaid) * 100 || 0;
  const totalRoyalties = sumBy(rows.filter(({ type }) => type === 'royalties'), 'received') || 0;
  const totalCreationsSold = sumBy(rows.filter(({ type, isCreation }) => ['salePrimary', 'saleSecondary'].includes(type) && isCreation), 'received') || 0;
  const totalOtherSold = sumBy(rows.filter(({ type, isCreation }) => ['salePrimary', 'saleSecondary'].includes(type) && !isCreation), 'received') || 0;
  const {
    handleClickHeader,
    orderKey,
    orderAscending,
    orderedRows,
  } = useOrderTable<ProfitRow>(rows);
  const {
    limitedRows,
    canShowMore,
    showMore,
  } = useShowMore<ProfitRow>(orderedRows);
  const breakpoint = useBreakpoint();
  const { price, currency } = useDataContext();
  const isEth = currency === 'eth';
  return isLoading ? <LoadIcon $animating style={ { margin: 15, marginLeft: 10 } } /> : (
    <>
      <TableStats data={
        [
          {
            label: 'Total paid',
            value: totalPaid,
            currency: xtz,
            sub: price && currency && `(${formatAmount(totalPaid * price, isEth ? 3 : 0)} ${currency})`,
          },
          {
            label: 'Total raw income',
            value: totalReceived,
            currency: xtz,
            sub: price && currency && `(${formatAmount(totalReceived * price, isEth ? 3 : 0)} ${currency})`,
          },
          {
            label: 'Total gain',
            value: totalGain,
            currency: xtz,
            color: getPerfColor(totalPerf),
            sub: price && currency && `(${formatAmount(totalGain * price, isEth ? 3 : 0)} ${currency})`,
          },
          {
            label: 'Creations sold',
            value: totalCreationsSold,
            currency: xtz,
            sub: price && currency && `(${formatAmount(totalCreationsSold * price, isEth ? 3 : 0)} ${currency})`,
          },
          {
            label: 'Royalties received',
            value: totalRoyalties,
            currency: xtz,
            sub: price && currency && `(${formatAmount(totalRoyalties * price, isEth ? 3 : 0)} ${currency})`,
          },
          {
            label: 'Other artists sold',
            value: totalOtherSold,
            currency: xtz,
            sub: price && currency && `(${formatAmount(totalOtherSold * price, isEth ? 3 : 0)} ${currency})`,
          },
        ]
      }
      />
      <TextLight style={ { marginTop: 15, marginBottom: 10 } }>
        - The paid amount is the last purchase price before a sale (LIFO approach).
        <br />
        - The received amount corresponds to either the royalties received,
        or the net proceeds of a sale
        (the value of the sale, minus the 2.5% HEN marketplace fees and the objkt creator royalties).
        <br />
        - Royalties are only collected on secondary sales
      </TextLight>
      {
        orderedRows.length === 0 ? (
          <StatusText>Nothing sold yet</StatusText>
        ) : (
          <>
            <Table key={ `table.${address}.${breakpoint}` }>
              <Thead>
                <Tr>
                  <SortableTh
                    onClick={ () => handleClickHeader('type') }
                    $active={ orderKey === 'type' }
                    $ascending={ orderAscending }
                  >
                    Type
                  </SortableTh>
                  <SortableTh
                    onClick={ () => handleClickHeader('timestamp') }
                    $active={ orderKey === 'timestamp' }
                    $ascending={ orderAscending }
                  >
                    Date
                  </SortableTh>
                  <SortableTh
                    onClick={ () => handleClickHeader('duration') }
                    $active={ orderKey === 'duration' }
                    $ascending={ orderAscending }
                  >
                    Held for
                  </SortableTh>
                  <SortableTh
                    onClick={ () => handleClickHeader('objkt.title') }
                    $active={ orderKey === 'objkt.title' }
                    $ascending={ orderAscending }
                  >
                    Objkt
                  </SortableTh>
                  <SortableTh
                    onClick={ () => handleClickHeader('objkt.royalties') }
                    $active={ orderKey === 'objkt.royalties' }
                    $ascending={ orderAscending }
                    style={ { textAlign: 'right' } }
                  >
                    Royalties
                  </SortableTh>
                  <SortableTh
                    onClick={ () => handleClickHeader('objkt.creator.name') }
                    $active={ orderKey === 'objkt.creator.name' }
                    $ascending={ orderAscending }
                  >
                    Creator
                  </SortableTh>
                  <SortableTh
                    onClick={ () => handleClickHeader('paid') }
                    $active={ orderKey === 'paid' }
                    $ascending={ orderAscending }
                    style={ { textAlign: 'right' } }
                  >
                    Paid
                  </SortableTh>
                  <SortableTh
                    onClick={ () => handleClickHeader('received') }
                    $active={ orderKey === 'received' }
                    $ascending={ orderAscending }
                    style={ { textAlign: 'right' } }
                  >
                    Raw income
                  </SortableTh>
                  <SortableTh
                    onClick={ () => handleClickHeader('gain') }
                    $active={ orderKey === 'gain' }
                    $ascending={ orderAscending }
                    style={ { textAlign: 'right', whiteSpace: 'no-wrap' } }
                  >
                    Gain
                  </SortableTh>
                </Tr>
              </Thead>
              <Tbody>
                {
                  limitedRows.map(({
                    id,
                    type,
                    timestamp,
                    durationLabel,
                    objkt,
                    paid,
                    paidHash,
                    received,
                    receivedHash,
                    gain,
                  }) => (
                    <Tr key={ `valuation.${address}.${id}` }>
                      <Td style={ { textTransform: 'capitalize' } }>
                        <Emoji v={ typeEmojis[type] } />
                        <span style={ { marginLeft: 8, position: 'relative', bottom: 1 } }>
                          {typeLabels[type]}
                        </span>
                      </Td>
                      <Td>
                        { timestamp ? calendar(parseISO(timestamp)) : '-' }
                      </Td>
                      <Td>
                        { durationLabel }
                      </Td>
                      <Td style={ { width: '100%' } }>
                        <Link href={ `/o/${objkt.id}` }>
                          { objkt.title }
                        </Link>
                      </Td>
                      <Td style={ { textAlign: 'right' } }>
                        { `${formatAmount(objkt.royalties / 10)}%` }
                      </Td>
                      <Td>
                        <TextTruncated>
                          <UserTableItem user={ objkt.creator } />
                        </TextTruncated>
                      </Td>
                      <Td style={ { textAlign: 'right' } }>
                        <ExternalLink href={ paidHash && `https://tzkt.io/${paidHash}` }>
                          { paid ? `${formatAmount(paid, 2)} ${xtz}` : '-' }
                        </ExternalLink>
                      </Td>
                      <Td style={ { textAlign: 'right' } }>
                        <ExternalLink href={ receivedHash && `https://tzkt.io/${receivedHash}` }>
                          { received ? `${formatAmount(received, 2)} ${xtz}` : '-' }
                        </ExternalLink>
                      </Td>
                      <Td style={ { textAlign: 'right', color: getPerfColor(gain) } }>
                        { gain ? `${gain > 0 ? '' : ''}${formatAmount(gain, 2)} ${xtz}` : '-' }
                      </Td>
                    </Tr>
                  ))
                }
              </Tbody>
            </Table>
            {
              canShowMore ? (
                <ShowMoreButton onClick={ showMore }>Show more</ShowMoreButton>
              ) : null
            }
          </>
        )
      }
    </>
  );
};

export default ProfitFeed;

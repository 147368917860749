/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'graphql-request';
import { useQuery } from 'react-query';
// import { User } from '~/types';
import orderBy from 'lodash/orderBy';
import { groupBy, sumBy } from 'lodash';
import { ADDRESSES } from '~/utils/addresses';
import { userFragment, useTransformUser } from '~/graphql';
import { User } from '~/types';
import useGraphqlClient from './useGraphqlClient';

const ArtistCollectorsQuery = gql`
    query ArtistCollectors(
        $address: String!,
        $limit: Int
    ) {
        token_holder(
            order_by: {
                token_id: desc
            }
            limit: $limit
            where: {
                quantity: {
                    _gt: "0"
                },
                token: {
                    creator_id: {
                        _eq: $address
                    }
                },
                holder_id: {
                    _nin: [
                        $address,
                        ${ADDRESSES.burn},
                        ${ADDRESSES.v1},
                        ${ADDRESSES.v2},
                        "KT1FvqJwEDWb1Gwc55Jd1jjTHRVWbYKUUpyq",
                        "KT1XjcRq5MLAzMKQ3UHsrue2SeU2NbxUrzmU"
                    ]
                }
            }
        ) {
            count: quantity
            holder {
                ${userFragment}
            }
            __typename
        }
    }
`;

type Holder = {
  count: number;
  holder: User;
};

const useArtistCollectors = (address: string) => {
  const transformUser = useTransformUser();
  const gqlClient = useGraphqlClient();
  const query = useQuery<Holder[]>(
    ['artist.collectors', address],
    async () => {
      const { token_holder = [] } = await gqlClient<{ token_holder: Holder[] }>(
        ArtistCollectorsQuery,
        { address },
      );
      return orderBy(token_holder, ({ count }) => count).reverse();
    },
    { enabled: !!address },
  );
  const entries = query.data || [];
  const holders = orderBy(
    Object.entries(groupBy(entries, 'holder.address')).map(([, values]) => ({
      count: sumBy(values, 'count'),
      holder: transformUser(values[0].holder),
    })),
    ['count'],
    ['desc'],
  );
  return {
    ...query,
    data: holders,
  };
};

export default useArtistCollectors;

export const useCollectorCount = (address: string) => {
  const query = useArtistCollectors(address);
  return {
    ...query,
    countLoading: query.status === 'loading',
    count: (query.data || []).length,
  };
};

/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'graphql-request';
import { useQuery } from 'react-query';
import { userFragment, useTransformToken } from '~/graphql';
import { Swap } from '~/types';
import useGraphqlClient from './useGraphqlClient';

const UserSwapQuery = gql`
  query UserSwapQuery($address: String) {
    swaps: swap(
      where: {
        creator: {
          address: {
            _eq: $address
          }
        },
        is_valid: {
          _eq: true,
        },
        contract_version: {
          _eq: 2,
        },
        status: {
          _eq: 0,
        }
      },
      order_by: {
        timestamp: desc
      }
    ) {
      id
      price
      timestamp
      status
      royalties
      contract_version
      amount
      amount_left
      is_valid
      ophash
      __typename
      creator {
        ${userFragment}
      }
      token {
        id
        title
        supply
        display_uri
        timestamp
        creator {
          ${userFragment}
        }
        trades(
          limit: 1,
          order_by: {
            timestamp: desc
          }
        ) {
          amount
          timestamp
          swap {
            price
          }
        }
      }
    }
  }
`;

const useUserSwaps = (address: string) => {
  const transformToken = useTransformToken();
  const gqlClient = useGraphqlClient();
  return useQuery<Swap[]>(
    ['user.swaps', address],
    async () => {
      const { swaps = [] } = await gqlClient<{ swaps: Swap[] }>(
        UserSwapQuery,
        { address },
      );
      return swaps.map((o) => ({
        ...o,
        token: transformToken(o.token),
        tokenRaw: o.token,
      }));
    },
    { enabled: !!address },
  );
};

export const useUserActivitySwaps = (address: string) => {
  const { data: swaps = [], ...query } = useUserSwaps(address);
  return {
    ...query,
    data: swaps.map((s) => ({
      ...s,
      buyer: {
        address,
      },
      seller: s.creator,
      type: 'listing',
    })),
  };
};

export default useUserSwaps;

const UserSwapCountQuery = gql`
  query UserSwapCountQuery($address: String) {
    swap_aggregate(
      where: {
        creator: {
          address: {
            _eq: $address
          }
        },
        is_valid: {
          _eq: true,
        },
        contract_version: {
          _eq: 2,
        },
        status: {
          _eq: 0,
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const useUserSwapCount = (address: string) => {
  const gqlClient = useGraphqlClient();
  const query = useQuery<number>(
    ['user.swaps.count', address],
    async () => {
      const { swap_aggregate } = await gqlClient(
        UserSwapCountQuery,
        { address },
      );
      return swap_aggregate?.aggregate?.count;
    },
    {
      enabled: !!address,
    },
  );
  return {
    ...query,
    count: query.data,
    countLoading: query.status === 'loading',
  };
};

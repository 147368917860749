/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import { useRouter } from 'next/router';
import React from 'react';
import styled from 'styled-components';
import { formatAmount } from '~/utils';
import { getUserLink } from '~/graphql';
import useAddress from '~/hooks/useAddress';
import { useCreatorCount } from '~/hooks/useSearchObjkts';
import { useUserSwapCount } from '~/hooks/useUserSwaps';
import { useCollectorCount } from '~/hooks/useArtistCollectors';
import { UserPageQuery } from '~/types';
import useCollectionCount from '~/hooks/useCollectionCount';
import MenuNav from './MenuNav';

const StyledMenuNav = styled(MenuNav)`
  margin-top: 15px;
`;

const UserMenu: React.FC<{ isLoading?: boolean }> = () => {
  const router = useRouter();
  const { name, address, section } = router.query as UserPageQuery;
  const userLink = getUserLink({ name, address });
  const realAddress = useAddress();
  const creatorCount = useCreatorCount(realAddress);
  const collectorCount = useCollectorCount(realAddress);
  const collectionCount = useCollectionCount(realAddress);
  const storeCount = useUserSwapCount(realAddress);
  return (
    <StyledMenuNav
      // label="Best-selling time period"
      sections={
        [
          {
            label: `Creations${creatorCount.countLoading ? ' (-)' : ` (${formatAmount(creatorCount.count, 0)})`}`,
            url: userLink,
            isActive: !section,
          },
          {
            label: `Collectors${collectorCount.countLoading ? ' (-)' : ` (${formatAmount(collectorCount.count, 0)})`}`,
            url: `${userLink}/collectors`,
            isActive: section === 'collectors',
          },
          {
            label: `Collection${collectionCount.countLoading ? ' (-)' : ` (${formatAmount(collectionCount.count, 0)})`}`,
            url: `${userLink}/collection`,
            isActive: section === 'collection',
          },
          {
            label: `For sale (${formatAmount(storeCount.count, 0)})`,
            url: `${userLink}/store`,
            isActive: section === 'store',
          },
          {
            label: 'Activity',
            url: `${userLink}/activity`,
            isActive: section === 'activity',
          },
          {
            label: 'Valuation',
            url: `${userLink}/valuation`,
            isActive: section === 'valuation',
          },
          {
            label: 'Revenue',
            url: `${userLink}/revenue`,
            isActive: section === 'revenue',
          },
        ]
      }
    />
  );
};

export default UserMenu;

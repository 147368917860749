/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import styled from 'styled-components';
import { useRouter } from 'next/router';
import React from 'react';
import sumBy from 'lodash/sumBy';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import { formatAmount } from '~/utils';
import CollectorFeed from '~/components/CollectorFeed';
import CreatorFeed from '~/components/CreatorFeed';
import { getUserLink, isValidSwap, useTransformToken } from '~/graphql';
import useAddress from '~/hooks/useAddress';
import useUser from '~/hooks/useUser';
import ValuationFeed from '~/components/ValuationFeed';
import useUserSwaps from '~/hooks/useUserSwaps';
import ActivityTable from '~/components/ActivityTable';
import StatusText from '~/components/StatusText';
import TokenList from '~/components/TokenList';
import { CountContainer, ObjktFilterRowContainer, RightSection } from '~/components/ObjktFilterRow';
import ObjktGridSelect from '~/components/ObjktGridSelect';
import { ArtistPageProps } from '~/pageProps/artist';
import { useDataContext } from '~/contexts/Data';
import StolenBanner from '~/components/StolenBanner';
import { User, UserPageQuery } from '~/types';
import useArtistCollectors from '~/hooks/useArtistCollectors';
import UserItem from '~/components/UserItem';
import ShowMoreButton from '~/components/ShowMoreButton';
import UserProfileSection, { useUserRedirect } from '~/components/UserProfileSection';
import UserMenu from '~/components/UserMenu';
import useUserActivity from '~/hooks/useUserActivity';
import ProfitFeed from '~/components/ProfitFeed';
import Layout from '~/components/Layout';
import useCopyToClipboard from '~/hooks/useCopyToClipboard';
import MenuNav from '~/components/MenuNav';

const ActivityFeed: React.FC<{ address?: string; }> = ({ address }) => {
  const [items, isLoading] = useUserActivity(address);
  return (
    <div style={ { marginTop: 15 } }>
      <ActivityTable
        items={ items }
        filters={
          [
            'listing',
            'buy',
            'sell',
            'transfer',
            'burn',
          ]
        }
        isLoading={ isLoading }
      />
    </div>
  );
};

const PageContainer = styled.div`
  flex: 1;
`;

const InvestorSection = ({ address }) => {
  const router = useRouter();
  const { name, subsection } = router.query as UserPageQuery;
  const userLink = getUserLink({ name, address });
  return (
    <>
      <MenuNav sections={
        [
          {
            label: 'Valuation',
            url: `${userLink}/insights/valuation`,
            isActive: !subsection || subsection === 'valuation',
          },
          {
            label: 'Revenue',
            url: `${userLink}/insights/revenue`,
            isActive: subsection === 'revenue',
          },
        ]
      }
      />
      <>
        {
          !subsection ? (
            <ValuationFeed address={ address } />
          ) : subsection === 'valuation' ? (
            <ValuationFeed address={ address } />
          ) : subsection === 'revenue' ? (
            <ProfitFeed address={ address } />
          ) : null
        }
      </>
    </>
  );
};

const StoreFeed = ({ address }) => {
  const transformToken = useTransformToken();
  const { data: user } = useUser(address);
  const { data: swaps = [], status } = useUserSwaps(address);
  const validSwaps = swaps.filter(isValidSwap);
  const grouped = groupBy(validSwaps, 'token.id');
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const tokens = Object.entries(grouped).reduce((res, [objktId, swaps_]) => {
    const { tokenRaw } = swaps_?.[0] || {};
    const token = transformToken({
      ...tokenRaw,
      swaps: swaps_,
    });
    res.push({
      ...token,
      swaps: swaps_,
      editions: sumBy(token.validSwaps, 'amount_left'),
    });
    return res;
  }, []);
  return (
    <>
      <ObjktFilterRowContainer>
        <CountContainer>
          {`Everything sold by ${user?.name || 'this artist'}`}
        </CountContainer>
        <RightSection>
          <ObjktGridSelect />
        </RightSection>
      </ObjktFilterRowContainer>
      <TokenList tokens={ tokens } isLoading={ status === 'loading' } />
    </>
  );
};

const CopyAddressesLink = styled.a``;

const CollectorsList = ({ address }) => {
  const { data: user } = useUser(address);
  const { data: collectors = [] } = useArtistCollectors(address);
  const rpp = React.useMemo(() => 5, []);
  const [limit, setLimit] = React.useState<number>(5);
  const collectorsLimited = collectors.slice(0, limit);
  const canShowMore = collectors.length > limit;
  const handleShowMore = () => setLimit((l) => l + rpp);
  const { justCopied, handleCopy } = useCopyToClipboard();
  const handleCopyAddresses = () => handleCopy(collectors.map(({ holder }) => holder.address).join('\n'));
  return (
    <>
      <ObjktFilterRowContainer>
        <CountContainer>
          {`${user?.name || 'This artist'}'s creations have been collected by ${formatAmount(collectors.length)} wallets `}
          (
          <CopyAddressesLink onClick={ handleCopyAddresses }>
            {justCopied ? 'copied to clipboard!' : 'copy addresses'}
          </CopyAddressesLink>
          )
        </CountContainer>
      </ObjktFilterRowContainer>
      {
        collectorsLimited.map(({ count, holder }) => (
          <UserItem key={ `artist.collector.${holder.address}` } collectedCount={ count } { ...holder } />
        ))
      }
      {
        canShowMore ? (
          <ShowMoreButton onClick={ handleShowMore }>Show more</ShowMoreButton>
        ) : null
      }
    </>
  );
};

const useIsBlockedProfile = (address: string) => {
  const { blockedWallets } = useDataContext();
  return blockedWallets.includes(address);
};

const Page: React.FC<ArtistPageProps> = (props) => {
  const {
    user: userRaw = {},
    isBlocked: isBlockedSSR,
    errorCode,
  } = props;
  const address = useAddress();
  const { data: userData = {} } = useUser(address);
  const user = {
    ...userRaw,
    ...userData,
  } as User;
  const isLoading = isEmpty(user);
  const router = useRouter();
  const { section } = router.query as UserPageQuery;
  const isBlockedClient = useIsBlockedProfile(address);
  const [hideProfile, setHideProfile] = React.useState<boolean>(true);
  const isBlockedWallet = isBlockedSSR || isBlockedClient;
  useUserRedirect(user);
  return (
    <Layout>
      {
        errorCode === 404 ? (
          <StatusText>Artist not found</StatusText>
        ) : isBlockedWallet && hideProfile ? (
          <div style={ { textAlign: 'center' } }>
            <h2 style={ { marginBottom: 10 } }>This profile is blocked</h2>
            <StatusText $center>The person behind this wallet has been banned because s.he did not respect the terms and conditions of hicetnunc.</StatusText>
            <StatusText $center>If you bought any pieces from her.him, they are still in your wallet though.</StatusText>
            <StatusText $center as="a" onClick={ () => setHideProfile(false) } style={ { fontWeight: 'bold' } }>View profile anyway</StatusText>
          </div>
        ) : (
          <PageContainer>
            <>
              {
                isBlockedWallet ? (
                  <StolenBanner>
                    BANNED PROFILE. This profile has been banned from hicetnunc, probably because the person behind it minted art stolen from someone else.
                  </StolenBanner>
                ) : null
              }
              <UserProfileSection { ...props } user={ user } isLoading={ isLoading } />
              <UserMenu isLoading={ isLoading } />
              {
                section === 'collection' ? (
                  <CollectorFeed address={ address } />
                ) : section === 'collectors' ? (
                  <CollectorsList address={ address } />
                ) : section === 'insights' ? (
                  <InvestorSection address={ address } />
                ) : section === 'valuation' ? (
                  <ValuationFeed address={ address } />
                ) : section === 'revenue' ? (
                  <ProfitFeed address={ address } />
                ) : section === 'activity' ? (
                  <ActivityFeed address={ address } />
                ) : section === 'store' ? (
                  <StoreFeed address={ address } />
                ) : (
                  <CreatorFeed address={ address } showFilters />
                )
              }
            </>
          </PageContainer>
        )
      }
    </Layout>
  );
};

export default Page;
